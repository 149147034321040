import React from "react";
import ContactUsForm from "./ContactUsForm";

function ContactUs() {
  return (
    <div>
      <div className="relative w-full h-[1300px] mt-48">
        <img src="ContactUs.gif" alt="" className="absolute bottom-0 left-0 w-full object-cover object-center sm:h-[500px] lg:h-[800px] h-[400px]" />
        <div className="absolute top-0 left-0 flex justify-center w-full h-full pt-24">
          <div className="flex flex-col items-center">
            
            <ContactUsForm />
      

            {/* Logo and others */}
            <div className="absolute bottom-0 flex flex-col items-center w-full px-4 mb-3 text-white sm:px-8 sm:grid lg:hidden">
              <div class="flex flex-row justify-between w-full">
                <div class="flex flex-col">
                  <img src="images/rotaframewhite.svg" alt="" width="150" height="150" class="self-start xl:hidden hidden sm:flex" />
                  <img src="images/rotaframewhite.svg" alt="" width="100" height="100" class="self-start sm:hidden" />
                  <div class="grid flex-col mt-5 justify-items-start gap-2">
                    <a href="home" class="pr-10 text-xs sm:text-sm font-medium text-white hover:text-blue-600">Home</a>
                    <a href="home" class="pr-10 text-xs sm:text-sm font-medium text-white hover:text-blue-600">About Us</a>
                    <a href="#service" class="pr-10 text-xs sm:text-sm font-medium text-white hover:text-blue-600">Services</a>
                    <a href="#portfolio" class="pr-10 text-xs sm:text-sm font-medium text-white hover:text-blue-600">Portfolio</a>
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="sm:mr-24 sm:flex flex-col hidden">
                    <div className="text-sm font-bold text-white sm:text-xl xl:text-2xl">Hire Us On</div>
                    <a href="https://www.upwork.com/freelancers/~013b0ff8a3f60143e8?mp_source=share"><div><img src="images/upwork.png" alt="" width={100} height={100} /></div></a>
                    <a href="https://twitter.com/rotaframe"><div><img src="images/contra.png" alt="" width={100} height={100} /></div></a>
                  </div>
                  <div class="sm:mr-24 flex flex-col sm:hidden">
                    <div className="text-sm font-bold text-white sm:text-xl xl:text-2xl">Hire Us On</div>
                    <a href="https://www.upwork.com/freelancers/~013b0ff8a3f60143e8?mp_source=share"><div><img src="images/upwork.png" alt="" width={80} height={80} /></div></a>
                    <a href="https://twitter.com/rotaframe"><div><img src="images/contra.png" alt="" width={80} height={80} /></div></a>
                  </div>
                  <div class="flex flex-col pl-8 sm:pl-0">
                    <div className="text-sm font-bold text-white sm:text-xl xl:text-2xl">Hire Us On</div>
                    <div className="flex-row hidden mt-5 sm:flex">
                      <a href="https://www.linkedin.com/company/rotaframetechnology/"><img src="images/SocialMedia1.svg" alt="" width={20} height={20} className="mr-4" /></a>
                      <a href="https://twitter.com/rotaframe"><img src="images/SocialMedia2.svg" alt="" width={20} height={20} className="mr-4" /></a>
                      <a href="https://www.instagram.com/rotaframe?igsh=MTljZmRsenlieDJ1Mg%3D%3D&utm_source=qr"><img src="images/SocialMedia3.svg" alt="" width={20} height={20} className="mr-4" /></a>
                      <a href="https://www.facebook.com/profile.php?id=61559333861356&mibextid=LQQJ4d"><img src="images/SocialMedia4.svg" alt="" width={20} height={20} /></a>
                    </div>
                    <div className="flex flex-row mt-5 sm:hidden">
                      <a href="https://www.linkedin.com/company/rotaframetechnology/"><img src="images/SocialMedia1.svg" alt="" width={15} height={15} className="mr-2" /></a>
                      <a href="https://twitter.com/rotaframe"><img src="images/SocialMedia2.svg" alt="" width={15} height={15} className="mr-2" /></a>
                      <a href="https://www.instagram.com/rotaframe?igsh=MTljZmRsenlieDJ1Mg%3D%3D&utm_source=qr"><img src="images/SocialMedia3.svg" alt="" width={15} height={15} className="mr-2" /></a>
                      <a href="https://www.facebook.com/profile.php?id=61559333861356&mibextid=LQQJ4d"><img src="images/SocialMedia4.svg" alt="" width={15} height={15} /></a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-screen px-5 pt-5 sm:px-0 sm:w-full">
                <div className="flex flex-col justify-center w-full h-8 bg-white rounded-lg">
                  <div className="flex flex-col items-center justify-between px-5 sm:flex-row">
                    <div className="items-center text-xs text-black">Copyright &#9400; 2024 | Rotaframe Technology</div>
                    <div className="items-center text-xs text-black">Privacy & Policy | Terms & Conditions</div>
                  </div>
                </div>


              </div>

            </div>

            



            {/* Other Footer Content */}
            <div className="hidden w-[1440px] h-full lg:grid">
              <div className="absolute bottom-0 flex flex-col items-center justify-center w-[1440px] text-white">
                <div className="flex flex-row w-full gap-24 px-16">
                  <div className="flex flex-col w-2/6 text-white">
                    <img src="images/RotaFrameYellow.svg" alt="" width={180} height={180} className="self-start xl:hidden" />
                    <img src="images/RotaFrameYellow.svg" alt="" width="200" height="200" class="self-start hidden xl:grid" />
                    <div className="flex flex-row justify-between pt-4">
                      <a href="home" className="pr-5 text-base font-medium text-white xl:text-lg hover:text-yellow-500">Home</a>
                      <a href="home" className="pr-5 text-base font-medium text-white xl:text-lg hover:text-yellow-500">About Us</a>
                      <a href="#service" className="pr-5 text-base font-medium text-white xl:text-lg hover:text-yellow-500">Services</a>
                      <a href="#portfolio" className="pr-5 text-base font-medium text-white xl:text-lg hover:text-yellow-500">Portfolio</a>
                    </div>
                  </div>
                  <div className="flex flex-col w-2/6 pl-24 text-white">
                    <span className="mr-32 text-2xl text-white font-dela-gothic-one">Hire Us On</span>
                    <div className="flex flex-row w-full pt-2">
                      <a href="https://www.upwork.com/freelancers/~013b0ff8a3f60143e8?mp_source=share"><img src="images/upwork.png" alt="" width={120} height={100} className="mr-5" /></a>
                      <a href="https://twitter.com/rotaframe"><img src="images/contra.png" alt="" width={160} height={100} /></a>
                    </div>
                  </div>
                  <div className="flex flex-col w-2/6 text-right text-white">
                    <span className="text-2xl text-white font-dela-gothic-one">Get in touch</span>
                    <div className="flex flex-row justify-end w-full gap-5 pt-4 text-white">
                    <a href="https://www.linkedin.com/company/rotaframetechnology/"><img src="images/SocialMedia1.svg" alt="" width={25} height={25} className="" /></a>
                      <a href="https://twitter.com/rotaframe"><img src="images/SocialMedia2.svg" alt="" width={25} height={25} className="" /></a>
                      <a href="https://www.instagram.com/rotaframe?igsh=MTljZmRsenlieDJ1Mg%3D%3D&utm_source=qr"><img src="images/SocialMedia3.svg" alt="" width={25} height={25} className="" /></a>
                    <a href="https://www.facebook.com/profile.php?id=61559333861356&mibextid=LQQJ4d"><img src="images/SocialMedia4.svg" alt="" width={25} height={25} /></a>
                    </div>
                  </div>
                </div>

                <div className="w-[1440px] px-5 pt-5 pb-1">
                  <div className="flex flex-col justify-center w-full h-12 mb-2 bg-white rounded-lg ">
                    <div className="flex flex-row items-center justify-between px-6 lg:text-sm xl:text-base">
                      <div className="items-center text-black">Copyright &#9400; 2024 | Rotaframe Technology</div>
                      <div className="items-center text-black">Privacy & Policy | Terms & Conditions</div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Your content here */}
    </div>
  );
}

export default ContactUs;
