import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
import logo from '../assets/rotaframe black.png';
import { TfiMenu } from "react-icons/tfi";

const Navbar = () => {
  let links = [
    { name: 'Home', link: '#home' },
    { name: 'About Us', link: '#home' },
    { name: 'Services', link: '#service' },
    { name: 'Portfolio', link: '#portfolio' },
  ];

  // State to manage mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className='flex flex-col items-center w-full'>
    <div className="fixed top-0  z-50 w-screen md:w-[1440px] px-4 flex flex-col items-center" style={{backgroundColor:'rgba(0,0,0,0.8'}}>
      <div className="flex w-full flex-row items-center justify-between px-2 py-2 md:py-5 mx-5 mt-5 rounded-[10px] md:rounded-[24px] lg:px-10 sm:px-5 md:px-3" style={{backgroundColor:'rgba(29,29,29,0.8'}}>
        <div className='flex flex-row items-center justify-between w-full'>

          <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-black">
            <span className="px-2 py-3 pt-2 mr-3">
              {/* <img src={logo} alt="" className='logo' style={{ width: '100%', height: '35px' }} /> */}
              <div>
                <img src="images/Rotaframe YellowAsset 17.svg" alt="Description of SVG" width={200} height={140} className='hidden sm:flex md:hidden lg:flex' />
                <img src="images/Rotaframe YellowAsset 17.svg" alt="Description of SVG" width={110} height={50} className='flex sm:hidden' />
                <img src="images/Rotaframe YellowAsset 17.svg" alt="Description of SVG" width={140} height={50} className='hidden md:flex lg:hidden' />
              </div>
            </span>
          </div>
          <ul className="items-center justify-center hidden w-full gap-8 md:flex md:items-center md:flex-row">
            {links.map((link, index) => (
              <li key={index} className="text-lg font-semibold lg:text-xl xl:text-lg md:text-base hover:text-base">
                <a
                  href={link.link}
                  className="px-5 py-[12px] text-yellow-500 duration-500 rounded-[12px] hover:text-black hover:bg-white hover:shadow-xl"
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>

          <div className='flex flex-row gap-4'>
            <button className="md:h-8 h-4 font-semibold text-black bg-yellow-500 border-yellow-500 rounded-[8px] md:rounded-[12px] hover:shadow-xl w-[100px] md:w-36 xl:w-[140px] xl:text-lg hover:bg-yellow-400 hover:text-black xl:h-12 flex flex-row justify-center items-center py-[18px] md:py-[24px] text-xs" onclick="scrollToSection('ContactUsForm')" >
            <a href="#contactUs" className="duration-500">
    Contact Us
  </a>
</button>
            
            <div className='md:hidden'>
              {/* Conditionally render the close mark icon based on the mobile menu state */}
              {isMobileMenuOpen ? (
                <IoClose className='font-bold text-black w-7 h-7 sm:w-9 sm:h-9 md:w-8 md:h-8' onClick={toggleMobileMenu} />
              ) : (
                <TfiMenu className='font-bold text-black w-7 h-7 sm:w-9 sm:h-9' onClick={toggleMobileMenu} />
              )}
            </div>
          </div>
        </div>
      </div>
        {/* Conditionally render the mobile menu based on the mobile menu state */}
        {isMobileMenuOpen && (
          <div className="w-screen mt-2 md:hidden">
          <div className='mx-4 bg-gray-200'>
            <ul className="flex flex-col items-center">
              {links.map((link, index) => (
                <li key={index} className="flex flex-row justify-center w-full h-8 my-2 text-lg font-medium sm:text-xl group hover:bg-black hover:text-white">
                  <a
                    href={link.link}
                    className="text-black duration-500 group-hover:text-white"
                  >
                    {link.name}
                  </a>
                </li>
              ))}
            </ul>
            </div>
          </div>
        )}

      
    </div>
      
    </div>
  );
}

export default Navbar;
